import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from "../views/MainView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView,
    redirect: '/home/page',
    children: [
      {
        path: '/home/page',
        name: 'homepage',
        component: () => import('../views/HomePage/HomeView.vue')
      },
      {
        path: '/product/a',
        name: 'productOne',
        component: () => import('../views/Product/ProductOne.vue')
      },
      {
        path: '/product/b',
        name: 'productTwo',
        component: () => import('../views/Product/ProductTwo.vue')
      },
      {
        path: '/product/c',
        name: 'productThree',
        component: () => import('../views/Product/ProductThree.vue')
      },
      {
        path: '/product/detail/:id',
        name: 'productDetail',
        component: () => import('../views/Product/ProductDetail.vue'),
        props: true
      },
      {
        path: '/about/us',
        name: 'aboutus',
        component: () => import('../views/AboutUs/AboutUs.vue')
      },
      {
        path: '/contact/us',
        name: 'contactus',
        component: () => import('../views/ContactUs/ContactUs.vue')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../views/Search/Search.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
