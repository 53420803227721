import request from "../http/index";
export function navListFunction(params){
    return request({
        url: 'nav/nav/list',
        method: 'get',
        params
    })
}

export function getHomeLogoFunction(){
    return request({
        url: 'home/home/logo',
        method: 'get'
    })
}

export function getHomeInfoFunction(){
    return request({
        url: 'home/home/info',
        method: 'get'
    })
}

export function getProductOneFunction(params){
    return request({
        url: 'productOne/productOne/list',
        method: 'get',
        params
    })
}

export function getProductTwoFunction(params){
    return request({
        url: 'productTwo/productTwo/list',
        method: 'get',
        params
    })
}

export function getProductThreeFunction(params){
    return request({
        url: 'productThree/productThree/list',
        method: 'get',
        params
    })
}

export function getProductOneDetailFunction(id) {
    return request({
        url: `productOne/productOne/detail/${id}`,
        method: 'get'
    })
}

export function getProductTwoDetailFunction(id) {
    return request({
        url: `productTwo/productTwo/detail/${id}`,
        method: 'get'
    })
}

export function getProductThreeDetailFunction(id) {
    return request({
        url: `productThree/productThree/detail/${id}`,
        method: 'get'
    })
}

export function getAboutUsInfoFunction(){
    return request({
        url: 'about/about/info',
        method: 'get'
    })
}

export function getContactUsInfoFunction(){
    return request({
        url: 'contact/contact/info',
        method: 'get'
    })
}

export function sendMessageFunction(params){
    return request({
        url: 'contact/contact/send/message',
        method: 'post',
        data: params
    })
}

export function getSettingFunction(){
    return request({
        url: 'other/setting/info', 
        method: 'get'
    })
}

export function getSearchFunction(params) {
    return request({
        url: 'other/search',
        method: 'post',
        data: params
    })
}